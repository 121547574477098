import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container, Title, getProperty } from "@btc/shared";

import * as styles from "./exhibitors.module.scss";

export const MobilityPartners = ({ title, items, ...rest }) =>
  Array.isArray(items) && items.length > 0 ? (
    <Section {...rest} size="md" noPaddingTop={true}>
      <Container>
        <Title className={styles.title}>{title}</Title>
        <div className={styles.container}>
          {items.map((item, index) => {
            const url = getProperty(item, "url");
            const name = getProperty(item, "name");
            const image = getProperty(item, "logo.childImageSharp.gatsbyImageData");

            return (
              <a href={url} key={index} target="_blank" rel="noreferrer" className={styles.item}>
                <GatsbyImage image={image} alt={name} />
              </a>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;

MobilityPartners.defaultProps = {
  title: null,
  items: [],
};

MobilityPartners.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export const query = graphql`
  fragment CommonEventMobilityPartnersFragment on MarkdownRemark {
    frontmatter {
      event {
        mobility_partners {
          title
          items {
            name
            logo {
              childImageSharp {
                gatsbyImageData(height: 50, layout: CONSTRAINED)
              }
            }
            url
          }
        }
      }
    }
  }
`;
