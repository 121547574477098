import React from "react";
import PropTypes from "prop-types";

import { getProperty } from "@btc/shared";

import { Facts as CommonFacts } from "../common/facts";

export const Facts = ({ data }) => {
  const items = getProperty(data, "items") || [];

  return <CommonFacts items={items} />;
};

Facts.defaultProps = {
  data: {},
};

Facts.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        suffix: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
