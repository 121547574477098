import React, { useState, useRef, useEffect, Fragment, forwardRef } from "react";
import PropTypes from "prop-types";

import { Button, Text, Title, Markdown, getProperty, Field } from "@btc/shared";

import { useMeta } from "hooks";

import * as styles from "./form.module.scss";

const FIELDS_MAPPING = {
  CHECKBOX: Field.Checkbox,
  DATE: Field.Date,
  EMAIL: Field.Email,
  NUMBER: Field.Number,
  SELECT: Field.Select,
  TEXT: Field.Text,
  TEXTAREA: Field.TextArea,
  HIDDEN: Field.Hidden,
};

const TRANSMISSION_STATUS = {
  IDLE: "1",
  SENDING: "2",
  SUCCESS: "3",
  FAILURE: "4",
  RETRYING: "5",
};

const fieldFactory = ({ type, ...fieldProps }, formProps) => {
  const Component = FIELDS_MAPPING[type];
  if (!Component) {
    return null;
  }

  return <Component {...fieldProps} {...formProps} />;
};

const valueProcessor = ({ type }, value) => {
  if (value) {
    return value;
  }

  switch (type) {
    case "CHECKBOX":
      return false;
    default:
      return "";
  }
};

const valueValidator = ({ required }, value) => {
  if (!required) {
    return true;
  }

  return !!value;
};

export const Form = forwardRef(
  (
    {
      initialState,
      formAttrs,
      hiddenFields,
      rows,
      labels,
      success,
      failure,
      onTransmitData,
      ...props
    },
    ref
  ) => {
    const meta = useMeta();
    const remarkRef = useRef(null);
    const wrapperRef = useRef(null);

    const [formState, setFormState] = useState(initialState);
    const [submitted, setSubmitted] = useState(false);
    const [transmissionStatus, setTransmissionStatus] = useState(TRANSMISSION_STATUS.IDLE);

    const labelRetry = getProperty(labels, "retry");
    const labelSubmit = getProperty(labels, "submit");
    const labelSending = getProperty(meta, "meta.form.status.sending");
    const errorMessage = getProperty(meta, "meta.form.error.required");

    const successTitle = getProperty(success, "title");
    const failureTitle = getProperty(failure, "title");
    const successContent = getProperty(success, "content");
    const failureContent = getProperty(failure, "content");

    const retryHandler = async () => {
      try {
        setTransmissionStatus(TRANSMISSION_STATUS.RETRYING);
        await onTransmitData(formState);
        setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
      } catch (e) {
        setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
        console.error(e);
      }
    };

    const submitHandler = async (event) => {
      event.preventDefault();

      const remark = getProperty(remarkRef, "current.value");
      if (remark) {
        return;
      }

      try {
        setTransmissionStatus(TRANSMISSION_STATUS.SENDING);
        await onTransmitData(formState);
        setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
      } catch (e) {
        setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
        console.error(e);
      }
    };

    const renderRow = (row, indexRow) => {
      const fields = getProperty(row, "fields") || [];

      return (
        <div key={indexRow} className={styles.row}>
          {fields.map((field, indexField) => (
            <div key={`${indexRow}-${indexField}`} className={styles.field}>
              {fieldFactory(field, {
                submitted,
                error: valueValidator(field, formState[field.name]) ? null : errorMessage,
                value: valueProcessor(field, formState[field.name]),
                onChange: (value) => {
                  setFormState((form) => ({
                    ...form,
                    [field.name]: value,
                  }));
                },
              })}
            </div>
          ))}
        </div>
      );
    };

    useEffect(() => {
      switch (transmissionStatus) {
        case TRANSMISSION_STATUS.SUCCESS:
        case TRANSMISSION_STATUS.FAILURE: {
          const wrapper = getProperty(ref || wrapperRef, "current");
          if (wrapper) {
            wrapper.scrollIntoView();
          }
          break;
        }
        default:
      }
    }, [wrapperRef, transmissionStatus]);

    return (
      <div className={styles.wrapper} ref={wrapperRef} {...props}>
        {transmissionStatus === TRANSMISSION_STATUS.SUCCESS && (
          <Fragment>
            <Title wrapper="h3" size="s5" color="primary">
              {successTitle}
            </Title>
            <Text>
              <Markdown content={successContent} />
            </Text>
          </Fragment>
        )}
        {(transmissionStatus === TRANSMISSION_STATUS.FAILURE ||
          transmissionStatus === TRANSMISSION_STATUS.RETRYING) && (
          <Fragment>
            <Title wrapper="h3" size="s5" color="primary">
              {failureTitle}
            </Title>
            <Text>
              <Markdown content={failureContent} />
            </Text>
            <Button
              type="border"
              color="primary"
              onClick={retryHandler}
              className={styles.retry}
              disabled={transmissionStatus === TRANSMISSION_STATUS.RETRYING}>
              {transmissionStatus === TRANSMISSION_STATUS.RETRYING ? labelSending : labelRetry}
            </Button>
          </Fragment>
        )}
        {(transmissionStatus === TRANSMISSION_STATUS.IDLE ||
          transmissionStatus === TRANSMISSION_STATUS.SENDING) && (
          <form {...formAttrs} onSubmit={submitHandler}>
            {hiddenFields}
            {rows.map(renderRow)}
            <input ref={remarkRef} type="text" className={styles.remark} />
            <Button
              type="border"
              color="primary"
              className={styles.submit}
              onClick={() => setSubmitted(true)}
              disabled={transmissionStatus === TRANSMISSION_STATUS.SENDING}>
              {transmissionStatus === TRANSMISSION_STATUS.SENDING ? labelSending : labelSubmit}
            </Button>
          </form>
        )}
      </div>
    );
  }
);

Form.defaultProps = {
  initialState: {},
  formAttrs: {},
  hiddenFields: null,
  labels: {
    retry: "Retry",
    submit: "Submit",
  },
  rows: [],
  success: {
    title: "Successfully sent",
    content: "Thank you for your message!",
  },
  failure: {
    title: "Sorry, but...",
    content: "something went wrong while sending your message!",
  },
  onTransmitData: async () => Promise.reject(new Error("Not implemented!")),
};

Form.propTypes = {
  initialState: PropTypes.object,
  formAttrs: PropTypes.object.isRequired,
  hiddenFields: PropTypes.node,
  labels: PropTypes.shape({
    retry: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired
  ),
  success: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  failure: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  onTransmitData: PropTypes.func.isRequired,
};
