import React from "react";
import PropTypes from "prop-types";

import { Section, Container, Button, Text, getProperty, Markdown } from "@btc/shared";

import * as styles from "./teaser.module.scss";

export const Teaser = ({ data }) => {
  const cite = getProperty(data, "cite");
  const copy = getProperty(data, "copy");
  const linkPath = getProperty(data, "link.path");
  const linkText = getProperty(data, "link.text");

  return (
    <Section size="md" align="center">
      <Container>
        <Text size="s4" weight="bold">
          <Markdown content={cite} />
        </Text>
        <div className={styles.copy}>
          <Text size="s3" type="gradient" transform="uppercase">
            {copy}
          </Text>
        </div>
        <Button wrapper="a" type="border" color="primary" href={linkPath}>
          {linkText}
        </Button>
      </Container>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    cite: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
  }).isRequired,
};
