import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Request } from "./sponsoring/request";
import { Teaser } from "./sponsoring/teaser";
import { Facts } from "./sponsoring/facts";
import { Expanders } from "./sponsoring/expanders";
import { Voices } from "./sponsoring/voices";
import { Sponsors } from "./sponsoring/sponsors";
import { Partners } from "./sponsoring/partners";
import { Exhibitors } from "./sponsoring/exhibitors";
import { MobilityPartners } from "./sponsoring/mobility-partners";
import { Meta } from "./common/meta";

const Template = ({ data }) => {
  const teaser = getProperty(data, "page.frontmatter.sponsoring.teaser");
  const facts = getProperty(data, "page.frontmatter.sponsoring.facts");
  const levels = getProperty(data, "page.frontmatter.sponsoring.levels");
  const booths = getProperty(data, "page.frontmatter.sponsoring.booths");
  const voices = getProperty(data, "page.frontmatter.sponsoring.voices");
  const form = getProperty(data, "page.frontmatter.sponsoring.request");
  const sponsors = getProperty(data, "sponsors.frontmatter.event.sponsors");
  const partners = getProperty(data, "partners.frontmatter.event.partners");
  const exhibitors = getProperty(data, "exhibitors.frontmatter.event.exhibitors");
  const mobility_partners = getProperty(data, "mobility_partners.frontmatter.event.mobility_partners");
  const meta = getProperty(data, "page.frontmatter.meta");

  return (
    <Fragment>
      <Teaser data={teaser} />
      <Facts data={facts} />
      {/*<Expanders data={levels} id="levels" />
      <Expanders data={booths} id="booths" />*/}
      <Voices data={voices} />
      <Request data={form} />
      <Sponsors data={sponsors} />
      <Exhibitors data={exhibitors} />
      <Partners data={partners} />
      <MobilityPartners data={mobility_partners} />
      <Meta data={meta} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }

        sponsoring {
          teaser {
            cite
            copy
            link {
              path
              text
            }
          }

          facts {
            items {
              name
              count
              suffix
              icon {
                publicURL
              }
            }
          }

          levels {
            title
            content
            items {
              alias
              subline
              name
              violator
              details {
                title
                subtitle
                content
              }
            }
          }

          booths {
            title
            content
            items {
              alias
              subline
              name
              violator
              details {
                title
                subtitle
                content
              }
            }
          }

          voices {
            title
            items {
              title
              content
            }
          }

          request {
            title
            labels {
              submit
              retry
            }
            rows {
              fields {
                name
                type
                label
                required
                options
              }
            }
            success {
              title
              content
            }
            failure {
              title
              content
            }
          }
        }
      }
    }

    sponsors: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventSponsorsFragment
    }

    partners: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventPartnersFragment
    }

    exhibitors: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventExhibitorsFragment
    }

    mobility_partners: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...CommonEventMobilityPartnersFragment
    }
  }
`;
