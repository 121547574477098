import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container, getProperty } from "@btc/shared";

import * as styles from "./sponsors.module.scss";

export const Sponsors = ({ items, ...rest }) => {
  const main = getProperty(items, "main") || [];
  const gold = getProperty(items, "gold") || [];
  const silver = getProperty(items, "silver") || [];
  const allSponsors = [...main, ...gold, ...silver];

  return allSponsors.length > 0 ? (
    <Section {...rest} size="md">
      <Container>
        <div className={styles.container}>
          {[main, gold, silver].map((items, index) => (
            <div className={styles.category} key={index}>
              {items.map((item, index) => {
                const url = getProperty(item, "url");
                const name = getProperty(item, "name");
                const image = getProperty(item, "logo.childImageSharp.gatsbyImageData");

                return (
                  <a
                    href={url}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.item}>
                    <GatsbyImage image={image} alt={name} />
                  </a>
                );
              })}
            </div>
          ))}
        </div>
      </Container>
    </Section>
  ) : null;
};

Sponsors.defaultProps = {
  items: {},
};

Sponsors.propTypes = {
  items: PropTypes.shape({
    main: PropTypes.arrayOf(PropTypes.object.isRequired),
    gold: PropTypes.arrayOf(PropTypes.object.isRequired),
    silver: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
};

export const query = graphql`
  fragment CommonEventSponsorsFragment on MarkdownRemark {
    frontmatter {
      event {
        sponsors {
          items {
            main {
              name
              logo {
                childImageSharp {
                  gatsbyImageData(height: 150, layout: CONSTRAINED)
                }
              }
              url
            }
            gold {
              name
              logo {
                childImageSharp {
                  gatsbyImageData(height: 75, layout: CONSTRAINED)
                }
              }
              url
            }
            silver {
              name
              logo {
                childImageSharp {
                  gatsbyImageData(height: 50, layout: CONSTRAINED)
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;
