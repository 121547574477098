import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Section, Container, Title } from "@btc/shared";

import { Form } from "../common/form";

const FORM_ATTRS = {
  action: "https://submit-form.com/qXb4QWVh",
  method: "POST",
  acceptCharset: "UTF-8",
  encType: "multipart/form-data",
};

export const Request = ({ data }) => {
  const ref = useRef(null);
  const { title, ...formData } = data || {};

  const submitHandler = async (data) => {
    const response = await fetch(FORM_ATTRS.action, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed with response: ${await response.text()}`);
    }
  };

  return (
    <Section id="request" color="black" size="md" align="center" ref={ref}>
      <Container>
        <Title>{title}</Title>
        <Form {...formData} ref={ref} formAttrs={FORM_ATTRS} onTransmitData={submitHandler} />
      </Container>
    </Section>
  );
};

Request.defaultProps = {
  data: {},
};

Request.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    labels: PropTypes.shape({
      retry: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
    }).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      })
    ),
    success: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
    failure: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
