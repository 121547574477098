import React from "react";
import PropTypes from "prop-types";

import { Container, Section, Text, ViewAwareCounter, getProperty } from "@btc/shared";

import * as styles from "./facts.module.scss";

export const Facts = ({ header, items, ...props }) =>
  Array.isArray(items) && items.length > 0 ? (
    <Section {...props} size="md" color="black">
      <Container>
        {header}
        <div className={styles.wrapper}>
          {items.map((item, index) => {
            const name = getProperty(item, "name");
            const count = getProperty(item, "count");
            const suffix = getProperty(item, "suffix");
            const iconSrc = getProperty(item, "icon.publicURL");

            return (
              <div key={index} className={styles.item}>
                <img src={iconSrc} alt={name} className={styles.icon} />
                <Text size="s3" type="special" transform="uppercase">
                  {name}
                </Text>
                <Text size="s5" weight="bold" className={styles.count}>
                  <ViewAwareCounter from={0} to={count} />
                  <span>{suffix}</span>
                </Text>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;

Facts.defaultProps = {
  header: null,
  items: [],
};

Facts.propTypes = {
  header: PropTypes.element,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      suffix: PropTypes.string,
    }).isRequired
  ).isRequired,
};
