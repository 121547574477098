import React from "react";
import PropTypes from "prop-types";

import { getProperty } from "@btc/shared";

import { Exhibitors as CommonExhibitors } from "../common/exhibitors";

export const Exhibitors = ({ data }) => {
  const title = getProperty(data, "title");
  const items = getProperty(data, "items") || [];

  return <CommonExhibitors title={title} items={items} />;
};

Exhibitors.defaultProps = {
  data: {},
};

Exhibitors.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
