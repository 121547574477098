import React from "react";
import PropTypes from "prop-types";

import { getProperty } from "@btc/shared";

import { Sponsors as CommonSponsors } from "../common/sponsors";

export const Sponsors = ({ data }) => {
  const items = getProperty(data, "items") || {};

  return <CommonSponsors items={items} />;
};

Sponsors.defaultProps = {
  data: {},
};

Sponsors.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.shape({
      main: PropTypes.arrayOf(PropTypes.object.isRequired),
      gold: PropTypes.arrayOf(PropTypes.object.isRequired),
      silver: PropTypes.arrayOf(PropTypes.object.isRequired),
    }),
  }).isRequired,
};
