import React from "react";
import PropTypes from "prop-types";
import { CarouselProvider, Slider, Slide, ButtonNext, ButtonBack } from "pure-react-carousel";

import { Section, Container, Text, Title, Markdown, getProperty, ArrowIcon } from "@btc/shared";

import * as styles from "./voices.module.scss";

export const Voices = ({ data }) => {
  const items = getProperty(data, "items") || [];
  const title = getProperty(data, "title");

  return Array.isArray(items) && items.length > 0 ? (
    <Section color="black" size="md" align="center" noPaddingBottom={true}>
      <CarouselProvider
        isIntrinsicHeight={true}
        lockOnWindowScroll={true}
        totalSlides={items.length}>
        <Container>
          <Title color="white">{title}</Title>
          <div className={styles.container}>
            <Slider>
              {items.map((item, index) => {
                const title = getProperty(item, "title");
                const content = getProperty(item, "content");

                return (
                  <Slide key={index} index={index}>
                    <div className={styles.inner}>
                      <Title wrapper="h3" transform="uppercase" className={styles.title}>
                        {title}
                      </Title>
                      <Text>
                        <Markdown content={content} />
                      </Text>
                    </div>
                  </Slide>
                );
              })}
            </Slider>
          </div>

          <div className={styles.buttons}>
            <ButtonBack className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonBack>
            <ButtonNext className={styles.button}>
              <ArrowIcon className={styles.icon} />
            </ButtonNext>
          </div>
        </Container>
      </CarouselProvider>
    </Section>
  ) : null;
};

Voices.defaultProps = {
  data: {},
};

Voices.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
